import { initMoreBooks } from './catalog_books_more_books'

let booksListNavigation = null

const sorting = (filteredBook) => {
    const booksTiles = document.querySelector('.books-list.row .books-tiles')
    booksTiles.innerHTML = ''
    filteredBook.forEach(book => {
        booksTiles.appendChild(book)
    })
    return filteredBook
}

const sortByA = (filteredBook) => {
    filteredBook.sort((a, b) => {
        const titleA = a.querySelector('.book-title').textContent.toLowerCase()
        const titleB = b.querySelector('.book-title').textContent.toLowerCase()
        return titleA.localeCompare(titleB)
    })
    return sorting(filteredBook)
}

const sortByZ = (filteredBook) => {
    filteredBook.sort((a, b) => {
        const titleA = a.querySelector('.book-title').textContent.toLowerCase()
        const titleB = b.querySelector('.book-title').textContent.toLowerCase()
        return titleB.localeCompare(titleA)
    })
    return sorting(filteredBook)
}

const sortByNew = (filteredBook) => {
    filteredBook.sort((a, b) => {
        const titleA = Number(a.dataset.issueYears)
        const titleB = Number(b.dataset.issueYears)
        return titleB - titleA
    })
    return sorting(filteredBook)
}

const sortByOld = (filteredBook) => {
    filteredBook.sort((a, b) => {
        const titleA = Number(a.dataset.issueYears)
        const titleB = Number(b.dataset.issueYears)
        return titleA - titleB
    })
    return sorting(filteredBook)
}


/**
 * According to the selected method, select the functions to sort
 * @param {*} visibleBooks
 * @returns
 */
const initSorting = (visibleBooks) => {
    let setMethod = null
    const catalogBooksSortBy = document.querySelector('.books-list-filter-mobile .catalog-books-sort-by')
    if (catalogBooksSortBy) {
        setMethod = catalogBooksSortBy.dataset.setMethod
            ? catalogBooksSortBy.dataset.setMethod
            : 'sort_-issue'
    } else if (!catalogBooksSortBy && booksListNavigation) {
        setMethod = booksListNavigation.querySelector('.navigation-sort').dataset.setMethod
            ? booksListNavigation.querySelector('.navigation-sort').dataset.setMethod
            : 'sort_-issue'
    }

    // From new
    if (setMethod === 'sort_-issue') {
        return sortByNew(visibleBooks)
    }
    // From old
    if (setMethod === 'sort_issue') {
        return sortByOld(visibleBooks)
    }
    // From A
    if (setMethod === 'sort_name') {
        return sortByA(visibleBooks)
    }
    // From Z
    if (setMethod === 'sort_-name') {
        return sortByZ(visibleBooks)
    }
}

/**
 * For the first page load and sorting changes in the dropdown menu
 */
const setSortingMethod = () => {
    booksListNavigation = document.querySelector('.books-list-navigation')
    const navigationSort = booksListNavigation.querySelector('.navigation-sort')
    const sortBySelect = booksListNavigation.querySelector('.navigation-sort #sort-by')
    const catalogBooksSortBy = document.querySelector('.books-list-filter-mobile .catalog-books-sort-by')
    let sortByRadios = null
    if (catalogBooksSortBy) {
        sortByRadios = catalogBooksSortBy.querySelectorAll('input[type="radio"][name="books-sort-by"]')
    }

    function handleEvent() {
        // From new
        if (this.value === 'sort_-issue') {
            navigationSort.dataset.setMethod = 'sort_-issue'
            catalogBooksSortBy ? catalogBooksSortBy.dataset.setMethod = 'sort_-issue' : null
        }
        // From old
        if (this.value === 'sort_issue') {
            navigationSort.dataset.setMethod = 'sort_issue'
            catalogBooksSortBy ? catalogBooksSortBy.dataset.setMethod = 'sort_issue' : null
        }
        // From A
        if (this.value === 'sort_name') {
            navigationSort.dataset.setMethod = 'sort_name'
            catalogBooksSortBy ? catalogBooksSortBy.dataset.setMethod = 'sort_name' : null
        }
        // From Z
        if (this.value === 'sort_-name') {
            navigationSort.dataset.setMethod = 'sort_-name'
            catalogBooksSortBy ? catalogBooksSortBy.dataset.setMethod = 'sort_-name' : null
        }
        initMoreBooks()
    }

    sortBySelect.addEventListener('input', handleEvent.bind(sortBySelect))
    if (catalogBooksSortBy) {
        sortByRadios.forEach(function(radio) {
            radio.addEventListener('input', handleEvent.bind(radio))
        })
    }
}

export { initSorting, setSortingMethod }
