/* global gettext */

let booksListSide = null

/**
 * Insert mobile collapse
 *
 * @param {*} params
 */
const insertCollapse = () => {
    booksListSide.insertAdjacentHTML('afterbegin', `
        <div class="books-list-filter-mobile">
            <h4 data-bs-toggle="collapse" href="#booksListFilterMobile" role="link"
            aria-expanded="true" aria-controls="booksListFilterMobile">
                ${gettext('Filters')}
            </h4>
            <div class="collapse show books-list-filter-content" id="booksListFilterMobile"></div>
        </div>
    `)
}

const insertSortBy = () => {
    const booksListFilterContent = booksListSide.querySelector('.books-list-filter-content')
    booksListFilterContent.insertAdjacentHTML('afterbegin', `
        <div class="catalog-books-sort-by">
            <h4 data-bs-toggle="collapse" href="#collapse-sort" role="link" aria-expanded="true"
                aria-controls="collapse-sort">${gettext('Sort by')}</h4>
            <div class="collapse show" id="collapse-sort">
                <ul>
                    <li>
                        <input type="radio" id="sort_-issue" value="sort_-issue" name="books-sort-by" checked>
                        <label for="sort_-issue">${gettext('from the latest')}</span></label>
                    </li>
                    <li>
                        <input type="radio" id="sort_issue" value="sort_issue" name="books-sort-by">
                        <label for="sort_issue">${gettext('from the oldest')}</span></label>
                    </li>
                    <li>
                        <input type="radio" id="sort_name" value="sort_name" name="books-sort-by">
                        <label for="sort_name">${gettext('by name A-Z')}</label>
                    </li>
                    <li>
                        <input type="radio" id="sort_-name" value="sort_-name" name="books-sort-by">
                        <label for="sort_-name">${gettext('by name Z-A')}</span></label>
                    </li>

                </ul>
            </div>
        </div>
    `)
}

const controlBooksFilter = () => {
    // Move books filter to mobile collapse and close all collapse
    const booksListMobile = booksListSide.querySelector('.books-list-filter-content')
    for (const list of booksListSide.querySelectorAll('.catalog-books-filter')) {
        booksListMobile.appendChild(list)
        list.querySelector('h4').classList.add('collapsed')
        list.querySelector('h4').setAttribute('aria-expanded', false)
        list.querySelector('.collapse').classList.remove('show')
    }
}

const initBooksFilter = () => {
    if (window.innerWidth < 992) {
        if (!booksListSide.querySelector('.books-list-filter-mobile')) {
            insertCollapse()
            insertSortBy()
            controlBooksFilter()
        }
    } else {
        for (const list of booksListSide.querySelectorAll('.catalog-books-filter')) {
            booksListSide.appendChild(list)
            if (list.classList.contains('books-issue-years')) {
                list.querySelector('h4').classList.remove('collapsed')
                list.querySelector('h4').setAttribute('aria-expanded', true)
                list.querySelector('.collapse').classList.add('show')
            }
        }

        booksListSide.querySelector('.books-list-filter-mobile')
            ? booksListSide.querySelector('.books-list-filter-mobile').remove()
            : null
    }
}

document.addEventListener('DOMContentLoaded', () => {
    booksListSide = document.querySelector('.books-list-side')
    if (booksListSide) {
        initBooksFilter()
        window.addEventListener('resize', () => {
            initBooksFilter()
        })
    }
})
