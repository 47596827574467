import { initMoreBooks } from './catalog_books_more_books'
import { setSortingMethod } from './catalog_books_sorting'

let books = []
let filteredData = {}

/**
 * Compare data with filter and books
 *
 */
const filteredBooks = (book, bookData) => {
    for (const key in filteredData) {
        if (Array.isArray(filteredData[key])) {
            if (!filteredData[key].some(value => bookData[key] && bookData[key].includes(value))) {
                book.classList.add('d-none')
                return false
            }
        }
    }
    book.classList.remove('d-none')
}

/**
 * Data with books into array
 *
 */
const bookValueToArray = (bookData) => {
    Object.entries(bookData).forEach(([key, value]) => {
        if (typeof value === 'string' && value.includes('|')) {
            bookData[key] = value.split('|').map(item => item.trim())
        } else {
            bookData[key] = [value]
        }
    })
}

/**
 * Prepare data with books
 *
 */
const createBookData = () => {
    books.forEach(book => {
        const bookData = {
            types: book.dataset.types,
            categories: book.dataset.categories,
            authors: book.dataset.authors,
            issueYears: book.dataset.issueYears,
            licenses: book.dataset.licenses,
            formats: book.dataset.formats,
            id: book.dataset.book_id,
        }
        bookValueToArray(bookData)
        filteredBooks(book, bookData)
    })
    initMoreBooks()
}

/**
 * Prepare data with filter
 *
 */
const fillFilteredData = () => {
    filteredData = {}
    for (const checkbox of document.querySelectorAll('.books-list-side input[type="checkbox"]')) {
        if (checkbox.checked) {
            const sectionKey = checkbox.dataset.section
            const sectionValue = checkbox.dataset.slug
            filteredData[sectionKey] = Object.prototype.hasOwnProperty.call(filteredData, sectionKey) ?
                (Array.isArray(filteredData[sectionKey])
                    ? filteredData[sectionKey].concat(sectionValue)
                    : [filteredData[sectionKey], sectionValue])
                : [sectionValue]
        }
    }
    createBookData()
}

document.addEventListener('DOMContentLoaded', () => {
    // Get books
    books = Array.from(document.querySelectorAll('.books-list div .book-item'))

    // Event to tiles and list icon
    const booksList = document.querySelector('.row.books-list')
    if (booksList) {
        const booksListNavigation = booksList.querySelector('.books-list-navigation')
        const tileListBox = booksListNavigation.querySelector('span:first-of-type')
        setSortingMethod()

        tileListBox.addEventListener('click', function(e) {
            e.preventDefault()
            if (e.target.alt === 'tiles') {
                booksList.querySelector('.books-tiles').classList.remove('books-list-list')
            }
            if (e.target.alt === 'list') {
                booksList.querySelector('.books-tiles').classList.add('books-list-list')
            }
            initMoreBooks()
        })
    }

    // Event for input checbox
    for (const checkbox of document.querySelectorAll('.books-list-side input[type="checkbox"]')) {
        checkbox.addEventListener('input', function() {

            books.forEach(book => {
                if (book.classList.contains('hide-book')) {
                    book.classList.remove('hide-book')
                }
            })

            if (booksList.querySelector('.btn-more')) {
                booksList.querySelector('.btn-more').remove()
            }

            fillFilteredData()
        })
    }
    initMoreBooks(books)
})
